import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import Title from 'components/title';
// const ReactMarkdown = require('react-markdown')

const Menu = ({ data }) => (
  <Layout>
    <Head pageTitle={data.menuJson.title} />
    <Box>
      <Title as="h1" size="large" style={{ fontFamily: 'Montserrat' }}>
        {data.menuJson.title}
      </Title>
      <div style={{ height: '1vh' }} />
      <div
        dangerouslySetInnerHTML={{
          __html: data.menuJson.content.childMarkdownRemark.html,
        }}
      />
      {/* <div><ReactMarkdown className="content" source={data.menuJson.content.childMarkdownRemark.rawMarkdownBody} /></div> */}
    </Box>
  </Layout>
);

Menu.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Menu;

export const query = graphql`
  query MenuQuery {
    menuJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
  }
`;
